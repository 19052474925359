var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Data Jenis Pembayaran"}},[_c('div',{staticClass:"custom-search"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"float-left"},[_c('b-form-group',[_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Cari","type":"text"},on:{"input":_vm.advanceSearch}})],1)],1)]),_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('div',{staticClass:"float-right"},[_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"gradient-primary","size":"sm"},on:{"click":_vm.AddData}},[_c('feather-icon',{attrs:{"icon":"UserPlusIcon"}}),_vm._v(" Tambah ")],1),_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"gradient-success","size":"sm"},on:{"click":_vm.exportToExcel}},[_c('feather-icon',{attrs:{"icon":"DownloadCloudIcon"}}),_vm._v(" Export ")],1)],1)])],1)],1),_c('div',{staticClass:"custom-table"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"column-options":{
        'active': {
          formatter: 'activeFormatter'
        }
      },"line-numbers":true,"rtl":_vm.direction,"search-options":{
  enabled: true,
  externalQuery: _vm.searchTerm,
},"pagination-options":{
  enabled: true,
  perPage: _vm.pageLength,
},"theme":"black-rhino"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('span',[_c('b-button-group',[_c('b-button',{staticClass:"btn-icon",attrs:{"size":"sm","variant":"gradient-info","title":"Ubah"},on:{"click":function($event){return _vm.toRouteEdit(props.row.id)}}},[_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1),_c('b-button',{staticClass:"btn-icon",attrs:{"size":"sm","variant":"gradient-danger","title":"Hapus"},on:{"click":function($event){return _vm.riseUpModalRemove(props.row.id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)],1)]):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Showing 1 to ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['10', '50', '100']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }